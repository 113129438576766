<template>
  <cui-a-c-l :roles="[1]" redirect>
    <div>
      <div class="row">
        <div class="col-lg-12">
          <a @click="goBack" class="margin-bottom" style="border: 1px black"><span class="fa fa-long-arrow-left"></span>
            Back</a>
          <br/>
          <br/>
          <div class="card">
            <div class="card-header">
              <div class="cui__utils__heading mb-0">
                <strong>Edit User</strong>
              </div>
              <div class="text-muted">Edit user information</div>
            </div>
            <div class="card-body">
              <a-form :form="form" @submit.prevent="handleSubmit">
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="First Name">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>User's first name.</span>
                    </template>
                    <a-input placeholder="User First Name" v-decorator="['firstName', { initialValue: userData.FirstName,
                rules: [
                  { required: true, message: 'First Name is required.' },
                  { max: 32, message: 'Maximum 32 characters allowed.'}
                  ]}, ]"/>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Last Name">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>User's last name.</span>
                    </template>
                    <a-input placeholder="User Last Name" v-decorator="['lastName', { initialValue: userData.LastName,
                rules: [
                  { required: true, message: 'Last Name is required.' },
                  { max: 32, message: 'Maximum 32 characters allowed.'}
                  ]}]"/>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Password">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>User's password.</span>
                    </template>
                    <a-input type="password" placeholder="User Password" v-decorator="['password', { initialValue: null,
                rules: [
                    { max: 64, message: 'Maximum 64 characters allowed.'}
                    ]}]"/>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Country">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>User's country.</span>
                    </template>
                    <a-select
                      v-decorator="['country', { initialValue: (userData.Country === '' ? [] : userData.Country),
                  rules : [{required: true}] } ]"
                      placeholder="Please select a country"
                      :showSearch="true"
                      :filterOption="true"
                      optionFilterProp="children"
                    >
                      <a-select-option v-for="obj in info.countries" :key="obj.CC">{{ obj.Country }}</a-select-option>
                    </a-select>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="City">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>User's city.</span>
                    </template>
                    <a-input placeholder="City" v-decorator="['city', { initialValue: userData.City,
                rules: [
                    { max: 20, message: 'Maximum 20 characters allowed.'}
                    ]}]"/>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Zip Code">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>User's zip code.</span>
                    </template>
                    <a-input type="number" placeholder="Zip Code" v-decorator="['zipCode', { initialValue: userData.ZipCode,
                 rules: [
                   {transform: function(value) { return Number(value)}, type: 'number'}
                   ]}]"/>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Address">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>User's address.</span>
                    </template>
                    <a-input placeholder="Address" v-decorator="['address', { initialValue: userData.Address,
                 rules: [
                    { max: 64, message: 'Maximum 64 characters allowed.'}
                    ]}]"/>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Phone">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>User's phone number.</span>
                    </template>
                    <a-input placeholder="Phone" v-decorator="['phone', { initialValue: userData.Phone,
                 rules: [
                   { message: 'Invalid phone number.' },
                   { max: 20, message: 'Maximum 20 characters allowed.'}
                   ]}]"/>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Skype">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>User's Skype username.</span>
                    </template>
                    <a-input placeholder="Skype" v-decorator="['skype', { initialValue: userData.Skype,
                 rules: [
                   { max: 32, message: 'Maximum 32 characters allowed.'}
                   ]}]"/>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Admin">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Should user have Administrator role?</span>
                    </template>
                    <a-checkbox :checked="role" v-decorator="['role', { }]" @change="role = !role">
                    </a-checkbox>
                  </a-tooltip>
                </a-form-item>
                <div class="ant-row ant-form-item">
                <a-divider class="mt-0"></a-divider>
                  <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                  <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                    <button type="submit" class="btn btn-success px-5  mx-2">Save Changes</button>
                    <button type="button" :class="'btn btn-'+(userData.Status === 0 ? 'danger' : 'primary')+' px-5'"
                            @click.prevent="toggleUserStatus">
                      {{ userData.Status == 0 ? 'Deactivate' : 'Activate' }} User
                    </button>
                    <button type="button" @click="$router.push('/users')" class="btn btn-light px-5 ml-2">Cancel
                    </button>
                  </div>

                </div>
              </a-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </cui-a-c-l>
</template>

<script>
import { mapState } from 'vuex'
import { Modal } from 'ant-design-vue'
import CuiACL from '@/components/cleanui/system/ACL/index'

export default {
  components: {
    CuiACL,
  },
  computed: {
    ...mapState(['info']),
    userData: function () {
      let userData = this.$store.getters['user/getUser'](this.$route.params.id)
      if (typeof userData === 'undefined') {
        this.$store.dispatch('user/LOAD_USERS')
        userData = {}
      }
      return userData
    },
  },
  data() {
    return {
      role: typeof this.$store.getters['user/getUser'](this.$route.params.id) !== 'undefined'
        ? this.$store.getters['user/getUser'](this.$route.params.id).Role === 1
        : false,
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    }
  },
  methods: {
    toggleUserStatus() {
      const status = this.userData.Status === 0 ? 'Deactivate' : 'Activate'
      const instance = this
      Modal.confirm({
        title: 'Are you sure you want to change user status?',
        content: h => <div>{status} user <strong>{this.userData.FirstName} {this.userData.LastName}</strong>?</div>,
        onOk() {
          const newStatus = instance.userData.Status === 0 ? 1 : 0
          instance.$store.dispatch('user/CHANGE_STATUS', {
            id: instance.userData.Id,
            status: newStatus,
          })
        },
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          values.role = this.role ? 1 : 0
          this.$store.dispatch('user/UPDATE_USER', {
            id: this.userData.Id,
            payload: values,
            callback: function () {
              this.$router.push({ name: 'users' }).catch(() => {
              })
            }.bind(this),
          })
        }
      })
    },
  },
  created() {
    this.$store.dispatch('info/LOAD_COUNTRIES')
    this.$store.dispatch('user/LOAD_USERS')
    // console.log(this.userData)
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}
</style>
